import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import "../global.css"
import Machine from "../images/machine.png"
import Work from "../images/work.png"
import Tool from "../images/tool.svg"
import Height from "../images/height.svg"
import Arrow from "../images/arrow.svg"
import cart from "../images/cart.svg"
import description from "../images/description.svg"
import { Container, Row, Col, Button } from "react-bootstrap"
import OfferCard from "../components/OfferCard"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container className="main-section">
      <div className="text-center ">
        <p className="big">Baumaschinenvermietung Rhein-Neckar</p>
      </div>
      <div className="img-container">
        <img src={Machine} alt="machine" />
      </div>
      <p className="pt-3">
        <b>Bagger, Frontlader oder ähnliches für Großbaustellen</b>
      </p>
      <div className="img-container text-right ">
        <img src={Work} alt="work" />
      </div>
      <p className="pt-3">
        <b>Kleinmaschinen, Anhänger uvm. für Kleinbaustellen</b>
      </p>
    </Container>
    <div className="dark-section">
      <Container>
        <h4 className="text-center pt-4">Aktuelle Angebote</h4>
        <Row>
          <Col sm={12} lg={6}>
            <OfferCard
              title="Hitachi ZX 19-6 Bj. 2020"
              showbuttons={false}
              shipSpan={"3.900 mm"}
              height={"3.900 mm"}
              depth={"3.900 mm"}
              canDeliver={true}
              deliverCost={35}
              priceDay={135}
              priceWeek={120}
            />
            <OfferCard
              title="Rasenschälmaschine"
              showbuttons={false}
              shipSpan={null}
              height={null}
              depth={null}
              canDeliver={true}
              deliverCost={null}
              priceDay={85}
              priceWeek={150}
            />
          </Col>
          <Col sm={12} lg={6}>
            <OfferCard
              title="HITACHI ZX 10U-6 Minibagger"
              showbuttons={false}
              shipSpan={"3.220 mm"}
              height={"3.010 mm"}
              depth={"1.780 mm"}
              canDeliver={true}
              deliverCost={35}
              priceDay={135}
              priceWeek={110}
            />
            <OfferCard
              title="Radlader Kramer 5035"
              showbuttons={false}
              shipSpan={null}
              height={null}
              depth={null}
              canDeliver={true}
              deliverCost={null}
              priceDay={135}
              priceWeek={120}
            />
          </Col>
        </Row>
        <div className="yellow-text text-center pt-3 pb-5">
          Monatspreise auf Anfrage!
        </div>
      </Container>
    </div>
  </Layout>
)

export default IndexPage
