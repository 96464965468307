import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Tool from "../images/tool.svg"
import Height from "../images/height.svg"
import Arrow from "../images/arrow.svg"
import cart from "../images/cart.svg"
import description from "../images/description.svg"

const OfferCard = ({
	title = "",
	showbuttons = false,
	shipSpan = 0,
	height = 0,
	depth = 0,
	canDeliver = true,
	deliverCost = 0,
	priceDay = 0,
	priceWeek = 0,
}) => {
	return (
		<div className="yellow-card my-2">
			<Row className="header px-3">
				<Col className="text-white">{title}</Col>
				{showbuttons && (
					<Col className="text-right">
						<Button variant="dark ">
							<img src={description} alt="description" height="20" />
						</Button>{" "}
						&nbsp;
						<Button variant="dark ">
							<img src={cart} alt="cart" height="20" />
						</Button>
					</Col>
				)}
			</Row>
			<hr />
			<Row>
				<Col className="black-text text-center">
					{shipSpan && shipSpan.length > 0 && (
						<p>
							<img src={Arrow} alt="arrow" width="20" /> &nbsp;{shipSpan}
						</p>
					)}
					{height && height.length > 0 && (
						<p>
							<img src={Height} alt="height" height="20" />
							&nbsp; {height}
						</p>
					)}
					{depth && depth.length > 0 && (
						<p>
							<img src={Tool} alt="tool" height="20" />
							&nbsp; {depth}
						</p>
					)}
				</Col>
				{canDeliver && (
					<Col className="faded-text">
						<p>Anlieferung möglich</p>
						{deliverCost && <p>Anhänger: + {deliverCost}€</p>}
					</Col>
				)}
				<Col className="white-text text-right">
					<br />
					{priceDay && <p>Tag: {priceDay}€</p>}
					{priceWeek && <p>Woche: {priceWeek}€</p>}
				</Col>
			</Row>
		</div>
	)
}

export default OfferCard
